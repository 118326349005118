<template>
  <v-row>
    <v-col cols="3" class="pb-0 pt-4">
      <v-select v-model="reportSelected" :items="yearItems" item-text="label" item-value="year" return-object solo dense flat background-color="transparent"></v-select>
    </v-col>
    <v-col cols="12" class="pt-0 pb-4 mb-4">
      <v-container>
        <v-row justify="space-between">
          <financeItem v-for="item in items" :key="item.title" :item="item"/>
        </v-row>
        <v-row v-if="reportSelected.link">
          <v-col cols="auto">
            <div @click="viewReport(reportSelected)" class="finance-report-link cursor-pointer">
              View {{ reportSelected.year }}'s Detailed Financial Statement
              <v-icon>mdi-open-in-new</v-icon>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>

import financeItem from './financeitem.vue'
import moment from 'moment'

export default {
  name: 'Finance',
  props: ['finances', 'investorData'],
  components: {
    financeItem
  },
  data: () => ({
    reportSelected: null
  }),
  computed: {
    yearItems () {
      return this.finances.map(function (f) {
        let o = Object.assign({}, f);
        o.label = f.year + ' Fiscal Year'
        return o
      })
    },
    items () {
      if (this.reportSelected.inProgress) {
        return [
          {
            title: this.reportSelected.progressMessage,
            subtitle: '',
            value: '',
            color: 'linear-gradient(180deg, rgb(13 38 13 / 68%), hsl(120deg 50% 25% / 58%))',
            sub: null
          }
        ]
      } else {
        let hasPayoutDate = this.reportSelected.payoutDate != null;
        let payoutsEnded = false;

        if (hasPayoutDate) {
          if (new moment(this.reportSelected.payoutDate) < new moment()) {
            payoutsEnded = true;
          }
        }

        let financeItems = [
          {
            title: 'Net Profit',
            subtitle: '',
            value: '$' + this.formatWithCommas(this.reportSelected.profit),
            color: 'linear-gradient(rgb(40 49 43), rgb(36 68 47))',
            sub: null
          },
          {
            title: 'Shareholder Dividends',
            subtitle: this.reportSelected.sharedProfitPercent + '% of yearly net profit',
            value: '$' +  this.formatWithCommas(this.reportSelected.dividends),
            color: 'linear-gradient(rgb(40 40 48), rgb(36 36 64))',
            sub: null
          },
          {
            title: 'Your Dividends',
            subtitle: '0% of shareholder dividends',
            value: '$0',
            color: 'linear-gradient(rgb(46 40 41), rgb(57 38 41))',
            sub: null
          },
        ]

        if (!hasPayoutDate) {
          financeItems[2].sub = null
        }
        else if (payoutsEnded) {
          financeItems[2].sub = {
            header: 'Payouts have ended this year',
            value: ''
          }
        } else {
          let payoutDate = moment(this.reportSelected.payoutDate).format('MMMM Do, YYYY')
          financeItems[2].sub = {
            header: 'Next shareholder payout',
            value: payoutDate // 'January 31st, 2023'
          }
        }

        if (this.investorData) {
          let foundInvestorData = this.investorData.find(id => id.financeReportId == this.reportSelected.id);
          if (foundInvestorData) {
            let percent = foundInvestorData.shares / this.reportSelected.commonShares;

            financeItems[2].subtitle =  (percent * 100) + '% of shareholder dividends';
            financeItems[2].value = '$' + this.formatWithCommas(foundInvestorData.dividend);
          }
        }

        return financeItems;
      }
    }
  },
  watch: {
    yearItems: function () {
      this.autoSelect();
    }
  },
  methods: {
    autoSelect: function () {
      if (this.yearItems.length > 0) {
        this.reportSelected = this.yearItems[0]; 
      }
    },
    viewReport: function (report) {
      window.open(report.link, '_blank').focus();
    },
    formatWithCommas: function (n) {
      return n.toLocaleString('en-US');
    }
  },
  mounted () {
    this.autoSelect();
  }
}
</script>
<style lang="scss" scoped>
.finance-report-link {
  border-radius: 15px;
  padding: 4px 16px;
  box-shadow: 0px 4px #1a1a1a;
  background-color: #282828;
}
</style>
