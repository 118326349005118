<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col>
        <v-img src="/backgrounds/investors-banner.png" cover style="z-index: -1" />
      </v-col>
    </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <v-container fill-height fluid v-if="data">
            <v-row>
              <!-- header -->
              <investorHeader :info="data.investorDashboardInfo" :featuredItem="featuredItem" :financeReports="data.financeReports" :investorData="investorData"/>
            </v-row>
            <v-row>
              <!-- finance -->
              <finance :finances="data.financeReports" :investorData="investorData"/>
            </v-row>
            <v-row>
              <!-- bulletin -->
              <bulletinBoard :bulletins="data.bulletinItems"/>
            </v-row>
            <v-row>
              <!-- chat -->
              <investorComments />
            </v-row>
            <v-row>
              <!-- schedule -->
              <schedule :schedules="data.scheduleItems" />
            </v-row>
            <v-row>
              <!-- content ideas -->
              <contentIdea />
            </v-row>
            <v-row>
              <!-- payment -->
              <paymentPreferences />
            </v-row>
          </v-container>
        </v-col>
     </v-row>
  </v-container>
</template>
<script>
import investorHeader from '../components/investors/investorHeader.vue'
import finance from '../components/investors/finance.vue'
import bulletinBoard from '../components/investors/bulletinBoard.vue'
import investorComments from '../components/investors/investorComments.vue'
import schedule from '../components/investors/schedule.vue'
import contentIdea from '../components/investors/contentIdea.vue'
import paymentPreferences from '../components/investors/paymentPreferences.vue'

import { Api } from '../js/api';
import moment from 'moment'

export default {
  name: 'Investors',
  props: [],
  components: {
    investorHeader,
    finance,
    bulletinBoard,
    investorComments,
    schedule,
    contentIdea,
    paymentPreferences
  },
  data: () => ({
    data: null,
    investorData: null,
    dummyReports: [{
      allocatedShares: 0,
      commonShares: 1000000,
      disruptShares: 10000000,
      dividends: 0,
      id: "f6ca1e43-3e5b-4e01-8a7e-08da3a824e7c",
      inProgress: true,
      link: "",
      payoutDate: "-01-31T00:00:00",
      profit: 0,
      progressMessage: "'s fiscal year is still in progress, financials will be posted in December.",
      sharedProfitPercent: 20,
      year: 0
    }]
  }),
  computed: {
    currentYear () {
      return new moment().format('YYYY');
    },
    featuredItem () {
      if (this.data) {
        return this.data.bulletinItems.find(b => b.featured);
      }
      return null
    },
    isLoggedIn () {
			return this.$store.getters.getIsLoggedIn;
		}
  },
  watch: {
  },
  methods: {
    getDashboard: function () {
      let scope = this;
      return new Promise((resolve) => {
        Api.get('Investor/dashboard').then(function (res) {
          scope.data = res.data;
          if (scope.data.financeReports.length == 0) {
            scope.data.financeReports = scope.dummyReports;
            scope.data.financeReports[0].payoutDate = scope.currentYear + scope.data.financeReports[0].payoutDate;
            scope.data.financeReports[0].progressMessage = scope.currentYear + scope.data.financeReports[0].progressMessage;
            scope.data.financeReports[0].year = scope.currentYear;
          }
          resolve(res.data);
        })
      })
    },
    getInvestorData: function () {
      let scope = this;
      return new Promise((resolve) => {
        Api.get('Investor/investorData').then(function (res) {
          scope.investorData = res.data;
          resolve(res.data);
        })
      })
    }
  },
  mounted () {
    this.getDashboard();

    if (this.isLoggedIn) {
      this.getInvestorData();
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
