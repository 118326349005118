<template>
  <v-container fluid>
    <v-row class="investor-header-row">
      <v-col></v-col>
      <v-col cols="6">
        <div class="investor-header text-center">
          <div class="shareholder-welcome pa-4 ma-2">
            <div class="shareholder-title">Good evening, {{ username }}</div>
            <div small class="yellow--text">
              {{ subtitle }}
              <v-tooltip v-if="investor" right content-class="shareholder-info-tooltip">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="yellow" small>mdi-information-outline</v-icon>
                </template>
                <div v-if="latestShareData">
                  Disrupt, inc has a total of {{ latestReportSharesLabel }} shares,<br>
                  {{ formatWithCommas(latestReport.commonShares) }} are owned by shareholders.<br>
                  You own <span class="yellow--text">{{ formatWithCommas(latestShareData.shares) }}</span> shares, or <span class="yellow--text">{{ (latestShareData.shares / latestReport.disruptShares) * 100 }}%</span> of Disrupt, inc.<br>
                  You'll recieve <span class="yellow--text">{{ ((latestShareData.shares / latestReport.commonShares) * 100).toFixed(2) }}%</span> of yearly shareholder dividends.<br>
                  <br>
                  Please contact Jak if you would like to inquire<br>
                  about selling your shares.
                </div>
                <div v-else>
                  Disrupt, inc has a total of {{ latestReportSharesLabel }} shares,<br>
                  {{ formatWithCommas(latestReport.commonShares) }} are owned by shareholders.<br>
                  You own <span class="yellow--text">{{ formatWithCommas(0.007 * latestReport.disruptShares) }}</span> shares, or <span class="yellow--text">0.7%</span> of Disrupt, inc.<br>
                  You'll recieve <span class="yellow--text">{{ (((0.007 * latestReport.disruptShares) / latestReport.commonShares) * 100).toFixed(2) }}%</span> of yearly shareholder dividends.<br>
                  <br>
                  Please contact Jak if you would like to inquire<br>
                  about selling your shares.
                </div>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-row class="pb-4 mb-4" align="center" align-content="center">
      <v-col v-if="featuredItem" cols="auto" @click="featuredItemClick" class="cursor-pointer">
        <div class="investor-header-bulletin" v-bind:style="{ 'background-image': 'url(\'' + featuredItem.image +'\')' }">
          <v-container fill-height class="py-0 px-2">
            <v-row justify="center" align="center">
              <v-col cols="12" class="pa-0" style="font-size: 12px;">
                {{ featuredItem.subtitle }}
              </v-col>
              <v-col cols="12" class="pa-0" style="font-size: 24px; line-height: 1">
                {{ featuredItem.title }}
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>

      <v-col cols="auto">
        <div class="investor-header-discord cursor-pointer" @click="nav(info.discord)">
        </div>
      </v-col>

      <v-col cols="auto">
        <div class="investor-header-contact text-center cursor-pointer" @click="nav(info.email)">
          <v-container fill-height>
            <v-row justify="center" align="center">
              <v-col cols="12" class="pa-0" style="font-size: 10px;">
                Contact
              </v-col>
              <v-col cols="12" class="pa-0" style="font-size: 32px; line-height: 1">
                Jak
              </v-col>
              <v-col cols="12" class="pa-0" style="font-size: 10px;">
                (CEO)
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'InvestorHeader',
  props: ['info', 'featuredItem', 'financeReports', 'investorData'],
  components: {
  },
  data: () => ({
  }),
  computed: {
    subtitle () {
      let shares = 0;
      if (this.investor) {
        if (this.latestShareData && this.latestReport) {
          shares = this.latestShareData.shares;
        }
      } else {
        shares = 23
      }
      return "You own " + this.formatWithCommas(shares) + " shares of </disrupt>";
    },
    username () {
      if (this.currentUser) {
        return this.currentUser.username;
      }
      return 'JohnDoe';
    },
    currentUser () {
      return this.$store.getters.getUser;
    },
    investor () {
      return this.$store.getters.hasKey('Investor');
    },
    latestShareData () {
      if (this.investorData && this.investorData.length > 0) {
        return this.investorData[0];
      }
      return null;
    },
    latestReport () {
      if (this.financeReports && this.financeReports.length > 0) {
        return this.financeReports[0];
      }
      return null;
    },
    latestReportSharesLabel () {
      if (this.latestReport) {
        return this.formatWithCommas(this.latestReport.disruptShares);
      }
      return "";
    }
  },
  watch: {
  },
  methods: {
    nav: function (url) {
      window.open(url, '_blank').focus();
    },
    featuredItemClick: function () {
      this.nav(this.featuredItem.buttonLink);
    },
    formatWithCommas: function (n) {
      return n.toLocaleString('en-US');
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.investor-header-row {
  margin-top: -100px;
}
.shareholder-title {
  font-size: 28px;
}
.investor-header {
  width: 100%;
}
.shareholder-welcome {
  border-radius: 10px;
  box-shadow: 0px 4px #1a1a1a;
  background-color: rgb(41,41,41);
}

.investor-header-bulletin {
  width: 300px;
  height: 100px;
  border-radius: 15px;
  background-size: cover;
  padding: 16px;
  box-shadow: 0px 4px #1a1a1a;
  text-align: center;
}

.investor-header-discord {
  width: 280px;
  height: 100px;
  border-radius: 15px;
  background-size: cover;
  background-image: url('/backgrounds/shareholders-discord.png');
  background-position: center;
  padding: 16px;
  box-shadow: 0px 4px #1a1a1a;
}
.investor-header-contact {
  width: 200px;
  height: 100px;
  border-radius: 15px;
  background-size: cover;
  background-image: url('/backgrounds/shareholders-contact.png');
  background-position: center;
  padding: 16px;
  box-shadow: 0px 4px #1a1a1a;
}
</style>
<style lang="scss">
.shareholder-info-tooltip {
  background-color: #00000069;
  border-radius: 15px;
}
</style>
