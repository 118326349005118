<template>
  <v-container class="mb-4">
    <v-row>
      <v-col class="pb-0">Content Idea</v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0" cols="8">
        <div class="idea-subtitle pb-2"><i>If your idea is used, we'll credit you - Jak</i></div>
        <v-textarea :readonly="readonly" :disabled="saving" class="idea-textarea" no-resize flat dense filled v-model="idea"></v-textarea>
        <v-btn @click="submitIdea" :disabled="disabled" color="primary" tile class="idea-button mt-2">
          Submit
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Api } from '../../js/api';
import { EventBus } from '../../eventBus.js';

export default {
  name: 'ContentIdea',
  props: [],
  components: {
  },
  data: () => ({
    idea: '',
    saving: false
  }),
  computed: {
    investor () {
      return this.$store.getters.hasKey('Investor');
    },
    readonly () {
      return !this.investor;
    },
    disabled () {
      return this.saving || this.idea == '';
    }
  },
  watch: {
  },
  methods: {
    submitIdea: function () {
      let scope = this;
      let data = {
        idea: scope.idea
      } 

      scope.saving = true;
      return new Promise((resolve) => {
        Api.post('Investor/contentIdea', data).then(function () {
          scope.saving = false;
          scope.idea = '';

          EventBus.$emit('alert', {
            text: 'Idea Submitted',
            alertColor: 'green',
            alertIcon: 'success'
          })

          resolve();
        })
      })
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
</style>
<style lang="scss">
.idea-subtitle {
  font-size: 12px;
}
.idea-button {
  text-transform: initial;
  font-size: 18px !important;
}

.idea-textarea .v-text-field__details {
  display: none;
}
</style>
