<template>
  <v-container>
    <v-row>
      <v-col class="pb-1">Disbursement Preference</v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0" cols="6">
        <div class="payment-preferences-description">
          Shareholder dividends are disbursed on a yearly basis,<br>
          please select your preferred payout method before January 31st, 2023.
        </div>

        <div class="pa-2">
          <v-row>
            <v-col>
              <v-btn :disabled="!investor" tile text class="payment-method-button" @click="changePaymentMethod('Bitcoin')">
                <v-radio-group readonly :value="bitcoinSelected ? 1 : 0">
                  <v-radio color="primary" :value="1"></v-radio>
                </v-radio-group>
                Bitcoin
              </v-btn>
            </v-col>
            <v-col>
              <v-btn :disabled="!investor" tile text class="payment-method-button" @click="changePaymentMethod('Paypal')">
                <v-radio-group readonly :value="paypalSelected ? 1 : 0">
                  <v-radio color="primary" :value="1"></v-radio>
                </v-radio-group>
                Paypal
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <div class="pt-4">
          <v-text-field :disabled="!investor" v-model="paymentPreference.paymentDetails" class="payment-preferences-field" background-color="#2a2a2a" flat solo :placeholder="detailsLabel" dense>
            <template v-slot:append>
              <v-btn tile color="primary" :disabled="!investor" @click="save">
                Save
              </v-btn>
            </template>
          </v-text-field>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Api } from '../../js/api';
import { EventBus } from '../../eventBus.js';

export default {
  name: 'PaymentPreferences',
  props: [],
  components: {
  },
  data: () => ({
    paymentPreference: {}
  }),
  computed: {
    investor () {
      return this.$store.getters.hasKey('Investor');
    },
    bitcoinSelected () {
      if (this.paymentPreference) {
        if (this.paymentPreference.paymentMethod == 'Bitcoin') {
          return true;
        }
      }
      return false;
    },
    paypalSelected () {
      if (this.paymentPreference) {
        if (this.paymentPreference.paymentMethod == 'Paypal') {
          return true;
        }
      }
      return false;
    },
    detailsLabel () {
      if (this.paymentPreference) {
        if (this.paymentPreference.paymentMethod == 'Paypal') {
          return 'Your Paypal Email';
        } else if (this.paymentPreference.paymentMethod == 'Bitcoin') {
          return 'Your BTC Address';
        }
      }
      return '';
    }
  },
  watch: {
  },
  methods: {
    changePaymentMethod: function (method) {
      let scope = this;
      scope.paymentPreference.paymentMethod = method;
    },
    getData: function () {
      let scope = this;
      return new Promise((resolve) => {
        Api.get('Investor/paymentPreferences').then(function (res) {
          scope.paymentPreference = res.data;
          if (!scope.paymentPreference.paymentMethod || scope.paymentPreference.paymentMethod == '') {
            scope.paymentPreference.paymentMethod = 'Bitcoin';
          }
          resolve(res.data);
        })
      })
    },
    save: function () {
      let scope = this;
      let data = scope.paymentPreference;
      return new Promise((resolve) => {
        Api.post('Investor/paymentPreferences', data).then(function () {
          EventBus.$emit('alert', {
            text: 'Payment Info Saved',
            alertColor: 'green',
            alertIcon: 'success'
          })
          resolve();
        })
      })
    }
  },
  mounted () {
    if (this.investor) {
      this.getData();
    }
  }
}
</script>
<style lang="scss" scoped>
.payment-preferences-description {
  font-size: 12px;
}
.payment-method-button {
  font-size: 24px;
  text-transform: none;
  border-radius: 20px;
  background-color: #2e2e2e;
  padding: 24px !important;
  box-shadow: 0px 4px #141414;
}

</style>
<style lang="scss">
.payment-preferences-field .v-input__slot {
  border-radius: 0 !important;
  padding-right: 0 !important;
}
</style>
