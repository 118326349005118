<template>
  <v-col cols="auto">
    <div class="finance-item" v-bind:style="{ 'background': item.color }">
      <div class="finance-item-title">{{ item.title }}</div>
      <div class="finance-item-subtitle">{{ item.subtitle }}</div>
      <div class="finance-item-value">{{ item.value }}</div>
    </div>
    <div v-if="item.sub" class="finance-sub-item mt-3" v-bind:style="{ 'background': item.color }">
      <div>{{ item.sub.header }}</div>
      <div>{{ item.sub.value }}</div>
    </div>
  </v-col>
</template>
<script>
export default {
  name: 'FinanceItem',
  props: ['item'],
  components: {
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.finance-item {
  border-radius: 15px;
  padding: 16px;
  box-shadow: 0px 4px #1a1a1a;
}

.finance-sub-item {
  border-radius: 15px;
  padding: 16px;
  box-shadow: 0px 4px #1a1a1a;
  background-color: #282828;
}

.finance-item-title {
  font-size: 18px;
}
.finance-item-subtitle {
  font-size: 18px;
}
.finance-item-value {
  font-size: 48px;
  margin-right: 12px;
}
</style>
