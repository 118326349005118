<template>
  <v-container class="mb-4 pb-4">
    <v-row>
      <v-col>Schedule</v-col>
    </v-row>
    <v-row>
      <scheduleItem v-for="item in schedules" :key="item.title" :item="item"/>
    </v-row>
  </v-container>
</template>
<script>
import scheduleItem from './scheduleItem.vue'

export default {
  name: 'Schedule',
  props: ['schedules'],
  components: {
    scheduleItem
  },
  data: () => ({
    items: [
      {
        occurence: 'Monthly',
        title: 'Shareholder Report',
        text: 'Towards the end of each month, Jal sends out a letter detailing the studio\'s current productions, releases, and general updates',
        imageUrl: 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/171647103929553.5f57dbb13439d.jpg'
      },
      {
        occurence: 'Quaterly',
        title: 'Shareholder Meeting',
        text: 'Join staff and fellow shareholders in a Discord call. Jak shares updates on studio direction & answers questions. (These meetings are recorded for later viewing)',
        imageUrl: 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/171647103929553.5f57dbb13439d.jpg'
      },
      {
        occurence: 'Yearly',
        title: 'Christmas Party',
        text: 'Join the studio\'s Christmas party in Virtual Reality. Meet the team, exchange VR gifts & have a good \'ole time.',
        imageUrl: 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/171647103929553.5f57dbb13439d.jpg'
      },
      {
        occurence: 'Yearly',
        title: 'Profit Disbursement',
        text: '20% of the studio\'s net-profit is distributed to shareholders.',
        imageUrl: 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/171647103929553.5f57dbb13439d.jpg'
      },
    ]
  }),
  computed: {
  },
  watch: {
  },
  methods: {
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
</style>
