<template>
  <v-container class="mb-4 pb-4">
    <v-row>
      <v-col>Shareholder Chat</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="investor-comments" v-if="investor">
        <comments v-if="forum" :forum="forum" :send="true" />
      </v-col>
      <v-col cols="12" class="investor-comments blur-container" v-else>
        <comments />
        <div class="investor-comments-blur">
          <v-container fill-height fluid>
            <v-spacer />
            <v-row justify="center" align="center">
              <v-col cols="auto">
                <div class="investor-comments-blur-text">
                  Investor Chatroom
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import comments from '../content/comments/comments.vue'

import { Api } from '../../js/api';

export default {
  name: 'InvestorComments',
  props: [],
  components: {
    comments
  },
  data: () => ({
    forum: null,
    fakeComments: [
      {
        "id": "a0c91b85-3a8d-4b7e-7628-08da42743259",
        "createdAt": "2022-05-30T20:40:29",
        "username": "bezz",
        "image": null,
        "text": "I own 99% btw",
        "upvotes": 0,
        "downvotes": 0,
        "userRating": 0
      },
      {
        "id": "9bdf3939-dd18-41c7-7627-08da42743259",
        "createdAt": "2022-05-30T20:40:05",
        "username": "hodl",
        "image": null,
        "text": "Disrupt to the moon!!!!1!11!",
        "upvotes": 0,
        "downvotes": 0,
        "userRating": 0
      },
      {
        "id": "101bc8ea-2403-48c2-e3b1-08da4273be10",
        "createdAt": "2022-05-30T20:36:50",
        "username": "humble investor",
        "image": null,
        "text": "I sure love investing!",
        "upvotes": 0,
        "downvotes": 0,
        "userRating": 0
      }
    ]
  }),
  computed: {
    investor () {
      return this.$store.getters.hasKey('Investor');
    }
  },
  watch: {
  },
  methods: {
    getInvestorForum: function () {
      let scope = this;
      return new Promise((resolve) => {
        Api.get('Forum/Investors').then(function (res) {
          scope.forum = res.data;
          resolve(res.data);
        })
      })
    },
    showBlurred: function () {
      this.$store.commit('setComments', this.fakeComments);
    }
  },
  mounted () {
    if (this.investor) {
      this.getInvestorForum();
    } else {
      this.showBlurred();
    }
  }
}
</script>
<style lang="scss" scoped>
.investor-comments {
  background-color: #242424;
}
.blur-container { 
  position: relative;
}
.investor-comments-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(4px);
}
.investor-comments-blur-text {
  font-size: 32px;
}
</style>
