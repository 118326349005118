<template>
  <v-container class="mb-4 pb-4">
    <v-row>
      <v-col class="pb-0 pt-4">
        Bulletin Board
      </v-col>
    </v-row>
    <v-row>
      <bulletinItem v-for="item in bulletins" :item="item" :key="item.id"/>
    </v-row>
  </v-container>
</template>
<script>

import bulletinItem from './bulletinItem.vue'

export default {
  name: 'BulletinBoard',
  props: ['bulletins'],
  components: {
    bulletinItem
  },
  data: () => ({
  }),
  computed: {
  },
  watch: {
  },
  methods: {
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
</style>
